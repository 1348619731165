import { AtdnButton, Colors } from '@ataden/design-system';
import styled from 'styled-components';
import { PackageVersionSelector } from './PackageVersionSelector';
import { useEffect, useState } from 'react';
import { Package } from '../domain/Package';
import { useGetServicePackages, useUpgradeServicePackage } from '../repositories/ServerRepository';
import { Service } from '../domain/Service';
import { ServiceStatusComponent } from './ServiceStatusComponent';

type ServiceTypeComponentProps = {
  serverId: string;
  service: Service;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 175px 200px 100px;
    column-gap: 16px;
    max-width: 800px;
    align-items: center;
`;

const ServiceNameWithStatus = styled.div`
    display: flex;
    flex-direction: row;
`;

type ServerVersionProps = {
  color: string;
};

const ServerVersion = styled.div<ServerVersionProps>`
    color: ${(props) => props.color};
`;

export const ServiceComponent = ({ serverId, service }: ServiceTypeComponentProps) => {

  const [packages, updatePackages] = useState<Package[]>([]);
  const [currentPackage, updateCurrentPackage] = useState<Package>();
  const [processing, updateProcessing] = useState<boolean>(false);

  const getServicePackages = useGetServicePackages();
  const upgradeServicePackage = useUpgradeServicePackage();

  useEffect(() => {
    getServicePackages(service.type).then((result) => {
      updatePackages(result);

      if (result.length > 0) {
        updateCurrentPackage(result[0]);
      }
    });
  }, [service.type]);

  const onPackageChange = (value: Package | undefined) => {
    updateCurrentPackage(value);
  };

  const onUpdateButton = () => {
    updateProcessing(true);
    upgradeServicePackage(serverId, service.type, currentPackage?.version!)
      .finally(() => {
        updateProcessing(false);
      });
  };

  const canUpdate = !processing;
  const isUpToDate = packages.length > 0 ? packages[0].version === service.version.trim() : true;

  return (
        <Container>
            <ServiceNameWithStatus>
                <ServiceStatusComponent status={service.status}/>
                <ServerVersion color={isUpToDate ? Colors.Black : Colors.Yellow900}>
                    {service.type}
                </ServerVersion>
            </ServiceNameWithStatus>
            <div>
                {service.version}
            </div>
            {packages.length > 0 && (
                <>
                    <div>
                        to <PackageVersionSelector packages={packages} currentPackage={currentPackage}
                                                   onPackageChange={onPackageChange}/>
                    </div>
                    <div>
                        {service.version !== currentPackage?.version && (
                            <AtdnButton disabled={!canUpdate}
                                        onClick={onUpdateButton}>Upgrade</AtdnButton>
                        )}
                    </div>

                </>
            )}
        </Container>
  );
};
